import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { RouterLink } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-israel-faqs',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
    RouterLink,
  ],
  templateUrl: './israel-faqs.component.html',
  styleUrl: './israel-faqs.component.scss',
})
export class IsraelFaqsComponent {
  currentDomain: string = window.location.host;
  constructor(private titleService: Title) {
    this.titleService.setTitle('Israel-FAQs');
  }

  toggleCollapse(event: Event, index: string): void {
    const button = event.target as HTMLElement;
    const element = document.getElementById(index);
    if (element) {
      const isExpanded = element.classList.contains('show');
      element.classList.toggle('show', !isExpanded);
      button.classList.toggle('collapsed', isExpanded);
      button.setAttribute('aria-expanded', (!isExpanded).toString());
    }
  }

  transformDomainLabel(url: string) {
    let domain = url.replace(/^https:\/\//, '');

    if (!domain.startsWith('www.')) {
      domain = 'www.' + domain;
    }

  return domain;
}
}
