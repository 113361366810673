import { Component, OnInit } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { RouterLink } from '@angular/router';
import { PDSService } from '../../../services/pds.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
    RouterLink,
  ],
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss',
})
export class FaqsComponent implements OnInit {
  pdsLink: string = '/pds';
  constructor(private pdsService: PDSService, private titleService: Title) {
    this.titleService.setTitle('FAQs');
  }
  
  toggleCollapse(event: Event, index: string): void {
    const button = event.target as HTMLElement;
    const element = document.getElementById(index);
    if (element) {
      const isExpanded = element.classList.contains('show');
      element.classList.toggle('show', !isExpanded);
      button.classList.toggle('collapsed', isExpanded);
      button.setAttribute('aria-expanded', (!isExpanded).toString());
    }
  }
  
  ngOnInit(): void {
    this.pdsService.getLatestPDS().subscribe((data) => {
      if (data && data.pds && data.pds.length > 0) {
        this.pdsLink = data.pds[0].previewUrl;
      }
    });
  }


}
