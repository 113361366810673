import { Injectable } from '@angular/core';
import { LocalCacheService } from './localCache.service';
import { environment } from '../../environments/environment';
declare global {
  interface Window {
    adobeDataLayer: any;
  }
}
@Injectable({
  providedIn: 'root',
})
export class AdobeAnalyticsService {
  constructor(private localCacheService: LocalCacheService) {}

  getDestinationsString(destinations: any) {
    let destinationString = '';
    destinations.forEach((destination: any, index: number) => {
      destinationString += destination.countryName;
      if (index < destinations.length - 1) {
        destinationString += ',';
      }
    });
    return destinationString;
  }

  getAgesString(ages: any) {
    let ageString = '';
    ages.forEach((age: any, index: number) => {
      ageString += age.toString();
      if (index < ages.length - 1) {
        ageString += ',';
      }
    });
    return ageString;
  }

  sendAdobeEvent(stepName: string) {
    this.safeExecute(() => {
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      window.adobeDataLayer.push({
        event: 'form.step',
        form: {
          name: 'Online Travel Insurance',
          stepName: stepName,
          countryName: this.getDestinationsString(quoteDetail.destinations),
          age: this.getAgesString(quoteDetail.ages),
          isClubMember: quoteDetail.isClubMember ? 'Yes' : 'No',
          fromDate: quoteDetail.fromDate,
          reutrnDate: quoteDetail.toDate,
          isCruise: quoteDetail.isCruise ? 'Yes' : 'No',
          isSki: quoteDetail.isSking ? 'Yes' : 'No',
          totalAmount: quoteDetail.premiums?.totalCost,
          productType: quoteDetail.isSingleTrip
            ? 'Single Trip'
            : 'Annual Multi Trip',
          policyNumber: quoteDetail.quoteNumber,
        },
      });
    });
  }

  safeExecute(fn: any) {
    try {
      fn();
    } catch (error) {
      console.error('An error occurred in the Google analytics code:', error);
    }
  }
}
