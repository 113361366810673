import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { RouterLink } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-israel',
  standalone: true,
  imports: [
    RouterLink,
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './israel.component.html',
  styleUrl: './israel.component.scss',
})
export class IsraelComponent {
  currentDomain: string = window.location.host;
  constructor(private titleService: Title) {
    this.titleService.setTitle('Travel Alert - Israel');
  }

  transformDomainLabel(url: string) {
      let domain = url.replace(/^https:\/\//, '');

      if (!domain.startsWith('www.')) {
        domain = 'www.' + domain;
      }

    return domain;
  }
}
