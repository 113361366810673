<div class="theme-static">
    <section class="header position-fixed w-100">
        <app-racv-common-header></app-racv-common-header>
    </section>

    <!-- End Header Part -->

    <!-- Start Page Header Title Part -->
    <section class="page-header-title">
        <div class="page-title-overlay"></div>
        <img src="assets/images/racq/page-header/faqs.jpg" alt="" class="w-100 page-title-image" />
        <div class="page-header-title-caption">
            <h1>Indonesia’s Mt Agung volcano</h1>
        </div>
    </section>
    <div class="container page-content py-5 page-content-info">
        <!-- <h3>Indonesia’s Mt Agung volcano</h3> -->
        <p>
            ***Updated 23/4/19 Volcanic ash hit parts of Bali, including Klungkung, Bangli, Denpasar, Badung and Tabanan
            on Sunday morning following the eruption of Mount Agung in Karangasem.</p>
        <p >The eruption, however, did not
            affect the operations of Bali’s main airport, I Gusti Ngurah Rai International Airport.</p>
        <p >Mt. Agung’s observatory post
            recorded the eruption at 3:21 a.m. local time, with the volcano spewing ash up to around 2,000 meters from
            the volcano’s peak.</p>
        <p >The local disaster mitigation
            agency immediately took precautionary action.</p>
        <p >“Our team has begun distributing
            face masks [to affected people],” Bali Disaster Mitigation Agency head Made Rentin said on Sunday.</p>
        <p >The Center for Volcanology and
            Geological Hazard Mitigation maintained the volcano’s alert level at the third of the four-tiered levels of
            the volcano alert system.</p>
        <p >“Residents and tourists should
            steer clear of the danger zone within a 4-kilometer radius from the crater,” the centre warned. The below
            policy restrictions are still in force.</p>
        <p ><strong><em>Updated notification as
                    at 2 January 2019</em></strong></p>
        <p >Mt Agung volcano is continuing to
            erupt resulting in the presence of ash cloud activity. There may be some impact on flights. Please check
            with your airline so see if you are affected.</p>
        <p >The below policy restrictions are
            still in force.</p>
        <p ><strong><em>Updated notification as
                    at 29 June 2018</em></strong></p>
        <p >There was an eruption overnight of
            the Mt Agung volcano resulting in an ash cloud. Some flights are cancelled or delayed. Please check with
            your airline if you are affected.</p>
        <p >The below policy restrictions are
            still in force.</p>
        <p ><strong><em>Updated notification as
                    at 7 December 2017</em></strong></p>
        <p >Ash emissions from the volcano have
            dissipated with all airlines now resuming normal operations. The alert level of the National Disaster
            Management Authority for Indonesia is closely monitored and can change level with little warning. Monitor
            local media reports, follow the instructions of local authorities, and stay outside the exclusion zone
            around the crater.</p>
        <p >The below policy purchase
            restrictions are still in force due to the volcano remaining at the highest alert level.</p>
        <p ><strong><em>Updated notification as
                    at 25 November</em></strong></p>
        <p >Ash emissions from 25 November
            onwards have caused disruptions to flights with Denpasar airport closed. Contact your airline for up-to-date
            information. The alert level of the National Disaster Management Authority for Indonesia is closely
            monitored and can change level with little warning. Monitor local media reports, follow the instructions of
            local authorities, and stay outside the exclusion zone around the crater.</p>
        <p >The below policy purchase
            restrictions are still in force with this known event.</p>
        <p ><strong><em>Original notification
                    issued on 23rd September</em></strong></p>
        <p >It has been reported through the
            Volcanic advisory and news websites, that a single eruption of Mt Agung will occur with smoke escaping from
            the mountain. Please check with your airline if your travel plans have been affected due to the volcano
            activity.</p>
        <p >All claims relating to the Mt Agung
            volcano incident will be assessed based on your circumstances and in accordance with the Product Disclosure
            Statement (PDS) applicable to your policy level.</p>
        <p >If your policy was purchased before
            7am (AEST) on 23rd September 2017</p>
        <p >-If you have commenced your trip
            and your transport has been delayed/cancelled there may be coverage for reasonable additional expenses for
            travel, accommodation or meals. All policy limits, conditions and exclusions will apply to any claim lodged.
        </p>
        <ul>
            <li>If you have not commenced your trip and your transport has been cancelled, delayed or rescheduled you
                may have coverage for cancellation or the rearrangement of your trip (if this is less than the
                cancellation cost). You will need to contact your travel agent or transport provider to obtain any
                applicable refund, credit or confirmation of cancellation fees applicable. Your travel provider may
                offer free options to rearrange your travel.</li>
        </ul>
        <p >If you purchased your policy after
            7am (AEST) on 23rd September 2017</p>
        <p>– Our policy does not provide cover
            for any event you were aware of before your policy cover commenced. If you purchased your policy after on
            23rd September 2017 we would expect this was done with knowledge of the current volcanic event and therefore
            there would be no coverage for any claim which was a result of this event.</p>
        <p >We will continue to monitor the
            situation and advise accordingly.</p>
    </div>

    <app-racv-footer></app-racv-footer>
</div>