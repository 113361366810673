<section class="homequote">
  <div class="container-md py-md-2 p-3 quotecontain-sidebar">
    <div class="row justify-content-center">
      <div class="col-lg-12 col-12 p-0">
        <div class="card quotecard m-2">
          <div class="card-body p-0">
            <div class="d-flex justify-content-between mobpad">
              <h3 class="fw-bold mb-3">{{ getaquotewording }}</h3>
            </div>
            <nav>
              <div class="nav nav-tabs quotehome" id="nav-tab" role="tablist">
                <button
                  class="nav-link text-dark ps-2 pe-2"
                  [ngClass]="{ active: isSingleTrip }"
                  id="nav-single-trip-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-single-trip"
                  type="button"
                  role="tab"
                  aria-controls="nav-single-trip"
                  aria-selected="isSingleTrip"
                  (click)="onSingleTripClick()"
                >
                  Single Trip
                </button>
                <button
                  class="nav-link text-dark ps-2 pe-2"
                  [ngClass]="{ active: !isSingleTrip }"
                  id="nav-multi-trip-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-multi-trip"
                  type="button"
                  role="tab"
                  aria-controls="nav-multi-trip"
                  aria-selected="!isSingleTrip"
                  (click)="onAMTSelect()"
                >
                  Annual Multi Trip
                </button>
                <p class="quotereq mb-0">* required fields</p>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active py-4"
                id="nav-single-trip"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
                tabindex="0"
              >
                <form action="" [formGroup]="singleTripForm">
                  <div class="row align-items-start">
                    <div class="g-2 align-items-start mt-0">
                      <div
                        class="p-2 lightcbg my-2 d-flex align-items-center rounded col-12"
                      >
                        <small class="smalltext"
                          >Provides cover for a single domestic or international
                          round trip.</small
                        >
                        <img
                          src="assets/images/racq/tooltipcolor.png"
                          class="tooltipc"
                        />
                      </div>
                    </div>

                    <div
                      class="g-2 col-12 col-md-12 form-floating ng-select-floating-container"
                    >
                      <ng-select
                        name="selectedCountries"
                        [items]="allCountries"
                        bindLabel="countryName"
                        [multiple]="true"
                        [(ngModel)]="selectedCountries"
                        [closeOnSelect]="false"
                        [clearOnBackspace]="true"
                        [clearSearchOnAdd]="true"
                        (change)="onModelChange($event)"
                        dropdownPosition="bottom"
                        maxSelectedItems="300"
                        [searchFn]="searchFn"
                        (search)="countrySearchFn($event)"
                        [isOpen]="searchTerms?.length >= 2 ? true : false"
                        [openOnEnter]="false"
                        [ngModelOptions]="{ standalone: true }"
                        [hideSelected]="true"
                        (focus)="ngSelectIsFocused = true"
                        (blur)="handlecountrySearcBlur($event)"
                      >
                        <ng-template ng-optgroup-tmp let-item="item">
                          <span style="vertical-align: middle">{{
                            item.name
                          }}</span>
                        </ng-template>
                      </ng-select>
                      <label
                        class="ng-select-floating-label"
                        [class.active]="
                          selectedCountries?.length || ngSelectIsFocused
                        "
                        for="selectedCountries"
                        >Travel Destination *</label
                      >
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="singleTripForm.get('travelDestination')?.errors?.['cautionCountry']"
                          >
                            {{singleTripForm.get('travelDestination')?.errors?.['cautionCountry']}}
                          </div>
                        </small>
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="singleTripForm.get('travelDestination')?.errors?.['cannotTravelCountry']"
                          >
                            <a
                              href="https://www.smartraveller.gov.au/destinations"
                              target="_blank"
                              >One or more of your destinations has a Travel
                              Alert, please click here to view the Alert(s).</a
                            >
                            {{singleTripForm.get('travelDestination')?.errors?.['cannotTravelCountry']}}
                          </div>
                        </small>
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="singleTripForm.get('travelDestination')?.errors?.['cannotTravelDomesticInternational']"
                          >
                            {{singleTripForm.get('travelDestination')?.errors?.['cannotTravelDomesticInternational']}}
                          </div>
                        </small>
                        <ng-container
                          *ngIf="
                            singleTripForm.get('travelDestination')?.invalid &&
                            (singleTripForm.get('travelDestination')?.dirty ||
                              singleTripForm.get('travelDestination')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="singleTripForm.get('travelDestination')?.errors?.['required']"
                            >
                              Please select the Travel Destination.
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="g-2 form-floating smallmargin">
                      <p-floatLabel>
                        <p-calendar
                          [(ngModel)]="departureDate"
                          name="departureDate"
                          dateFormat="dd/mm/yy"
                          [minDate]="minDepartDate"
                          [maxDate]="maxDepartDate"
                          (onSelect)="setReturnDateAndRange()"
                          (onBlur)="inputDepartValidation($event)"
                          [keepInvalid]="true"
                          aria-label="Depart *"
                          formControlName="depart"
                          inputStyleClass="form-control"
                          styleClass="full-width"
                          showOtherMonths="false"
                        >
                        </p-calendar>
                        <label class="greyPlaceholder" for="departureDate"
                          >Depart *</label
                        >
                      </p-floatLabel>
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <small class="text-danger">
                          <span
                            *ngIf="singleTripForm.get('depart')?.errors?.['invalidInputDepart']"
                          >
                            {{singleTripForm.get('depart')?.errors?.['invalidInputDepart']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="singleTripForm.get('depart')?.errors?.['departDateMoreThan18Months']"
                          >
                            {{singleTripForm.get('depart')?.errors?.['departDateMoreThan18Months']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="singleTripForm.get('depart')?.errors?.['departDateBeforeToday']"
                          >
                            {{singleTripForm.get('depart')?.errors?.['departDateBeforeToday']}}
                          </span>
                        </small>
                        <ng-container
                          *ngIf="
                            singleTripForm.get('depart')?.invalid &&
                            (singleTripForm.get('depart')?.dirty ||
                              singleTripForm.get('depart')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="singleTripForm.get('depart')?.errors?.['required']"
                            >
                              Please select the Leaving On date.
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>

                    <div class="g-2 form-floating smallmargin">
                      <p-floatLabel>
                        <p-calendar
                          [(ngModel)]="returnDate"
                          name="returnDate"
                          dateFormat="dd/mm/yy"
                          [minDate]="minReturnDate"
                          [maxDate]="maxReturnDate"
                          (onBlur)="inputReturnValidation($event, 'singleTrip')"
                          [keepInvalid]="true"
                          aria-label="Return *"
                          formControlName="return"
                          inputStyleClass="form-control"
                          styleClass="full-width"
                          showOtherMonths="false"
                        >
                        </p-calendar>
                        <label class="greyPlaceholder" for="departureDate"
                          >Return *</label
                        >
                      </p-floatLabel>
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <small class="text-danger">
                          <span
                            *ngIf="singleTripForm.get('return')?.errors?.['returnDateMoreThan365Days']"
                          >
                            {{singleTripForm.get('return')?.errors?.['returnDateMoreThan365Days']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="singleTripForm.get('return')?.errors?.['returnDateBeforeDepart']"
                          >
                            {{singleTripForm.get('return')?.errors?.['returnDateBeforeDepart']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="singleTripForm.get('return')?.errors?.['invalidInputReturn']"
                          >
                            {{singleTripForm.get('return')?.errors?.['invalidInputReturn']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="singleTripForm.errors?.['returnBeforeDepartDate']"
                          >
                            {{singleTripForm.errors?.['returnBeforeDepartDate']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="singleTripForm.errors?.['returnGreaterThanOneYear']"
                          >
                            {{singleTripForm.errors?.['returnGreaterThanOneYear']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span *ngIf="singleTripForm.errors?.['datesEqual']">
                            {{singleTripForm.errors?.['datesEqual']}}
                          </span>
                        </small>
                        <ng-container
                          *ngIf="
                            singleTripForm.get('return')?.invalid &&
                            (singleTripForm.get('return')?.dirty ||
                              singleTripForm.get('return')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="singleTripForm.get('return')?.errors?.['required']"
                            >
                              Please select the Returning On date.
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="g-2 align-items-start mt-0">
                      <div
                        class="p-2 lightcbg my-2 d-flex align-items-center rounded col-12"
                      >
                        <small class="smalltext"
                          >You can add multiple countries. Are you going on an
                          Australian ocean cruise for 2 nights or more? 1 night
                          Australian ocean cruises are automatically covered –
                          select Australia as your destination.</small
                        >
                        <ng-container
                          *ngIf="isTooltipVisibleTravDest; else toolTipOnHover"
                        >
                          <div
                            (click)="toggleTooltip()"
                            class="tooltip-container"
                          >
                            <img
                              src="assets/images/racq/help.png"
                              class="tooltipc"
                            />
                            <ng-container *ngIf="isTooltipVisibleTravDest">
                              <ng-container
                                *ngTemplateOutlet="travelDestinationTooltip"
                              ></ng-container>
                            </ng-container>
                          </div>
                        </ng-container>
                        <ng-template #toolTipOnHover>
                          <img
                            src="assets/images/racq/help.png"
                            class="tooltipc"
                            [pTooltip]="travelDestinationTooltip"
                            tooltipPosition="right"
                            tooltipStyleClass="travel-destination-tooltip"
                          />
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="g-2 form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="age1"
                        id="adult-1-age"
                        class="form-control"
                        placeholder=""
                        aria-label="Adult 1 Age *"
                        maxLength="3"
                        formControlName="age1"
                        pKeyFilter="int"
                      />
                      <label class="custom-label-placeholder" for="adult-1-age"
                        >Adult 1 Age *</label
                      >
                    </div>
                    <div class="col-12">
                      <ng-container
                        *ngIf="
                          singleTripForm.get('age1')?.invalid &&
                          (singleTripForm.get('age1')?.dirty ||
                            singleTripForm.get('age1')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="singleTripForm.get('age1')?.errors?.['required']"
                          >
                            {{errorMessages['first_age']?.['required']}}
                          </span>
                          <span
                            *ngIf="singleTripForm.get('age1')?.errors?.['min'] && showErrorAge1"
                          >
                            {{errorMessages['first_age']?.['min']}}
                          </span>
                          <span
                            *ngIf="singleTripForm.get('age1')?.errors?.['max'] && showErrorAge1"
                          >
                            {{errorMessages['first_age']?.['max']}}
                          </span>
                        </small>
                      </ng-container>
                    </div>
                    <div class="g-2 form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="age2"
                        id="adult-2-age"
                        class="form-control"
                        placeholder=""
                        aria-label="Adult 2 Age"
                        maxLength="3"
                        formControlName="age2"
                        pKeyFilter="int"
                      />
                      <label class="custom-label-placeholder" for="adult-2-age"
                        >Adult 2 Age</label
                      >
                    </div>

                    <div class="align-items-center">
                      <div class="col-12">
                        <ng-container
                          *ngIf="
                            showErrorAge2 &&
                            singleTripForm.get('age2')?.invalid &&
                            (singleTripForm.get('age2')?.dirty ||
                              singleTripForm.get('age2')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="singleTripForm.get('age2')?.errors?.['min']"
                            >
                              {{errorMessages['second_age']?.['min']}}
                            </span>
                            <span
                              *ngIf="singleTripForm.get('age2')?.errors?.['max']"
                            >
                              {{errorMessages['second_age']?.['max']}}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="p-1 align-items-start mt-0">
                      <div
                        class="p-2 lightcbg my-2 d-flex align-items-center rounded col-12"
                      >
                        <small class="smalltext"
                          >The travel policy covers up to two adult travellers.
                          Please enter the current age of the adult travellers.
                          Dependents such as children, grandchildren,
                          step-children and foster children aged 25 or under at
                          no extra cost. Specific criteria apply.</small
                        >
                        <ng-container
                          *ngIf="
                            !isTooltipVisibleDep;
                            else dependentToolTipOnHover
                          "
                        >
                          <img
                            src="assets/images/racq/help.png"
                            class="tooltipc"
                            [pTooltip]="dependentTooltip"
                            tooltipPosition="right"
                            tooltipStyleClass="dependent-tooltip"
                          />
                        </ng-container>
                        <ng-template #dependentToolTipOnHover>
                          <div
                            (click)="toggleTooltip()"
                            class="tooltip-container"
                          >
                            <img
                              src="assets/images/racq/help.png"
                              class="tooltipc"
                            />
                            <ng-container *ngIf="isTooltipVisibleDep">
                              <ng-container
                                *ngTemplateOutlet="dependentTooltip"
                              ></ng-container>
                            </ng-container>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <div class="g-2 form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="dependentsCount"
                        id="dependents"
                        class="form-control"
                        placeholder=""
                        aria-label="Dependents Count"
                        maxLength="2"
                        formControlName="dependentsCount"
                        pKeyFilter="int"
                      />
                      <label class="custom-label-placeholder" for="dependents"
                        >No. of Dependents</label
                      >
                    </div>
                    <div class="g-2 col-xl">
                      <div class="select-container">
                        <select
                          [(ngModel)]="state"
                          name=""
                          id="state"
                          class="form-select select"
                          formControlName="state"
                        >
                          <option
                            *ngFor="let state of stateList"
                            value="{{ state.id }}"
                          >
                            {{ state.code }}
                          </option>
                        </select>
                        <label
                          class="label"
                          for="state"
                          [class.floating]="state"
                          >State *</label
                        >
                      </div>
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <ng-container
                          *ngIf="
                            singleTripForm.get('state')?.invalid &&
                            (singleTripForm.get('state')?.dirty ||
                              singleTripForm.get('state')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="singleTripForm.get('state')?.errors?.['required']"
                            >
                              Please select the State you reside in.
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="g-2 form-floating">
                      <input
                        [(ngModel)]="promoCode"
                        type="text"
                        id="promo"
                        class="form-control"
                        placeholder=""
                        (blur)="validatePromoCode($event)"
                        formControlName="promoCode"
                      />
                      <label class="custom-label-placeholder" for="id"
                        >Enter promo code</label
                      >
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <ng-container>
                          <small class="text-danger">
                            <span>
                              {{ promoCodeMessage }}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="g-2 col-12">
                      <button
                        type="submit"
                        class="btn btn-lg qbtn btn-info w-100 text-center py-2"
                        (click)="onSingleTripSubmit()"
                      >
                        Get a Quote
                      </button>
                    </div>
                  </div>
                  <!-- Disclaimer -->
                  <div class="row g-2 align-items-start" *ngIf="sanitizedHtml">
                    <div class="col-12 col-sm-12 col-md-12 col-xl">
                      <div class="p-2 lightcbg my-2 align-items-center rounded">
                        <small><strong>Disclaimer</strong></small>
                        <p></p>
                        <p class="smalltext" [innerHTML]="sanitizedHtml"></p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div
                class="tab-pane fade py-4"
                id="nav-multi-trip"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
                tabindex="0"
              >
                <form action="" [formGroup]="multiTripForm">
                  <div class="row align-items-start">
                    <div class="g-2 align-items-start mt-0">
                      <div
                        class="p-2 lightcbg my-2 d-flex align-items-center rounded col-12"
                      >
                        <small class="smalltext"
                          >Provides cover for an unlimited number of trips
                          within a 12 month period, 250 kms from home. Max trip
                          duration 30, 45, 60 days. Please provide date you
                          depart for your first trip.</small
                        >
                        <img
                          src="assets/images/racq/tooltipcolor.png"
                          class="tooltipc"
                        />
                      </div>
                    </div>
                    <div class="g-2 col-12 col-md-12 form-floating">
                      <ng-select
                        name="selectedCountries"
                        [items]="allCountries"
                        bindLabel="countryName"
                        [multiple]="true"
                        [(ngModel)]="selectedCountries"
                        [closeOnSelect]="false"
                        [clearOnBackspace]="true"
                        [clearSearchOnAdd]="true"
                        (change)="onModelChange($event)"
                        dropdownPosition="bottom"
                        maxSelectedItems="300"
                        [searchFn]="searchFn"
                        (search)="countrySearchFn($event)"
                        [isOpen]="searchTerms?.length >= 2 ? true : false"
                        [openOnEnter]="false"
                        [ngModelOptions]="{ standalone: true }"
                        [hideSelected]="true"
                        (focus)="ngSelectIsFocused = true"
                        (blur)="handlecountrySearcBlur($event)"
                      >
                        <ng-template ng-optgroup-tmp let-item="item">
                          <span style="vertical-align: middle">{{
                            item.name
                          }}</span>
                        </ng-template>
                      </ng-select>
                      <label
                        class="ng-select-floating-label"
                        [class.active]="
                          selectedCountries?.length || ngSelectIsFocused
                        "
                        for="selectedCountries"
                        >Travel Destination *</label
                      >
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="multiTripForm.errors?.['cautionCountry']"
                          >
                            {{multiTripForm.errors?.['cautionCountry']}}
                          </div>
                        </small>
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="multiTripForm.errors?.['cannotTravelCountry']"
                          >
                            <a
                              href="https://www.smartraveller.gov.au/destinations"
                              target="_blank"
                              >One or more of your destinations has a Travel
                              Alert, please click here to view the Alert(s).</a
                            >
                            {{multiTripForm.errors?.['cannotTravelCountry']}}
                          </div>
                        </small>
                        <small class="text-danger">
                          <div
                            class="error-message"
                            *ngIf="multiTripForm.errors?.['cannotSelectDomesticAMTW2C']"
                          >
                            {{multiTripForm.errors?.['cannotSelectDomesticAMTW2C']}}
                          </div>
                        </small>
                        <ng-container
                          *ngIf="
                            multiTripForm.get('travelDestination')?.invalid &&
                            (multiTripForm.get('travelDestination')?.dirty ||
                              multiTripForm.get('travelDestination')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="multiTripForm.get('travelDestination')?.errors?.['required']"
                            >
                              Please select the Travel Destination.
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="g-2 form-floating smallmargin">
                      <p-floatLabel>
                        <p-calendar
                          [(ngModel)]="departureDate"
                          [minDate]="minDepartDate"
                          [maxDate]="maxDepartDate"
                          (onSelect)="setReturnDateAndRange()"
                          (onBlur)="inputDepartValidation($event)"
                          [keepInvalid]="true"
                          name="departureDateMulti"
                          dateFormat="dd/mm/yy"
                          aria-label="Depart *"
                          formControlName="depart"
                          inputStyleClass="form-control"
                          styleClass="full-width"
                          showOtherMonths="false"
                        >
                        </p-calendar>
                        <label class="greyPlaceholder" for="departureDateMulti"
                          >Depart *</label
                        >
                      </p-floatLabel>
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('depart')?.errors?.['invalidInputDepart']"
                          >
                            {{multiTripForm.get('depart')?.errors?.['invalidInputDepart']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('depart')?.errors?.['departDateMoreThan18Months']"
                          >
                            {{multiTripForm.get('depart')?.errors?.['departDateMoreThan18Months']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('depart')?.errors?.['departDateBeforeToday']"
                          >
                            {{multiTripForm.get('depart')?.errors?.['departDateBeforeToday']}}
                          </span>
                        </small>
                        <ng-container
                          *ngIf="
                            multiTripForm.get('depart')?.invalid &&
                            (multiTripForm.get('depart')?.dirty ||
                              multiTripForm.get('depart')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="multiTripForm.get('depart')?.errors?.['required']"
                            >
                              Please select the Leaving On date.
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="g-2 form-floating smallmargin">
                      <p-floatLabel>
                        <p-calendar
                          [(ngModel)]="returnDateMulti"
                          [minDate]="minReturnDateMulti"
                          [maxDate]="maxReturnDateMulti"
                          (onBlur)="inputReturnValidation($event, 'multiTrip')"
                          [keepInvalid]="true"
                          name="returnDateMulti"
                          dateFormat="dd/mm/yy"
                          aria-label="Return *"
                          formControlName="return"
                          inputStyleClass="form-control"
                          styleClass="full-width"
                          showOtherMonths="false"
                        >
                        </p-calendar>
                        <label class="greyPlaceholder" for="returnDateMulti"
                          >Return *</label
                        >
                      </p-floatLabel>
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('return')?.errors?.['invalidInputReturnMulti']"
                          >
                            {{multiTripForm.get('return')?.errors?.['invalidInputReturnMulti']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('return')?.errors?.['returnDateBeforeDepart']"
                          >
                            {{multiTripForm.get('return')?.errors?.['returnDateBeforeDepart']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.get('return')?.errors?.['returnDateMoreThan60Days']"
                          >
                            {{multiTripForm.get('return')?.errors?.['returnDateMoreThan60Days']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.errors?.['returnDateMoreThan60Days']"
                          >
                            {{multiTripForm.errors?.['returnDateMoreThan60Days']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.errors?.['returnBeforeDepartDateMulti']"
                          >
                            {{multiTripForm.errors?.['returnBeforeDepartDateMulti']}}
                          </span>
                        </small>
                        <small class="text-danger">
                          <span
                            *ngIf="multiTripForm.errors?.['datesEqualMulti']"
                          >
                            {{multiTripForm.errors?.['datesEqualMulti']}}
                          </span>
                        </small>
                        <ng-container
                          *ngIf="
                            multiTripForm.get('return')?.invalid &&
                            (multiTripForm.get('return')?.dirty ||
                              multiTripForm.get('return')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="multiTripForm.get('return')?.errors?.['required']"
                            >
                              Please select the Returning On date.
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="g-2 align-items-center mt-0">
                      <div
                        class="col-12 p-2 lightcbg my-2 d-flex align-items-center rounded"
                      >
                        <small class="smalltext"
                          >You can add multiple countries. Are you going on an
                          Australian ocean cruise for 2 nights or more? 1 night
                          Australian ocean cruises are automatically covered –
                          select Australia as your destination.</small
                        >
                        <ng-container
                          *ngIf="isTooltipVisibleTravDest; else toolTipOnHover"
                        >
                          <div
                            (click)="toggleTooltip()"
                            class="tooltip-container"
                          >
                            <img
                              src="assets/images/racq/help.png"
                              class="tooltipc"
                            />
                            <ng-container *ngIf="isTooltipVisibleTravDest">
                              <ng-container
                                *ngTemplateOutlet="travelDestinationTooltip"
                              ></ng-container>
                            </ng-container>
                          </div>
                        </ng-container>
                        <ng-template #toolTipOnHover>
                          <img
                            src="assets/images/racq/help.png"
                            class="tooltipc"
                            [pTooltip]="travelDestinationTooltip"
                            tooltipPosition="right"
                            tooltipStyleClass="travel-destination-tooltip"
                          />
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="g-2 form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="age1"
                        id="adult-1-age"
                        class="form-control"
                        placeholder=""
                        aria-label="Adult 1 Age *"
                        maxLength="3"
                        formControlName="age1"
                        pKeyFilter="int"
                      />
                      <label for="adult-1-age">Adult 1 Age *</label>
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <ng-container
                          *ngIf="
                            multiTripForm.get('age1')?.invalid &&
                            (multiTripForm.get('age1')?.dirty ||
                              multiTripForm.get('age1')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="multiTripForm.get('age1')?.errors?.['required']"
                            >
                              {{errorMessages['first_age']?.['required']}}
                            </span>
                            <span
                              *ngIf="multiTripForm.get('age1')?.errors?.['min'] && showErrorAge1Multi"
                            >
                              {{errorMessages['first_age_multi']?.['min']}}
                            </span>
                            <span
                              *ngIf="multiTripForm.get('age1')?.errors?.['max'] && showErrorAge1Multi"
                            >
                              {{errorMessages['first_age_multi']?.['max']}}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="g-2 form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="age2"
                        id="adult-2-age"
                        class="form-control"
                        placeholder=""
                        aria-label="Adult 2 Age"
                        maxLength="3"
                        formControlName="age2"
                        pKeyFilter="int"
                      />
                      <label for="adult-1-age">Adult 2 Age</label>
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <ng-container
                          *ngIf="
                            showErrorAge2Multi &&
                            multiTripForm.get('age2')?.invalid &&
                            (multiTripForm.get('age2')?.dirty ||
                              multiTripForm.get('age2')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="multiTripForm.get('age2')?.errors?.['min']"
                            >
                              {{errorMessages['second_age_multi']?.['min']}}
                            </span>
                            <span
                              *ngIf="multiTripForm.get('age2')?.errors?.['max']"
                              >{{errorMessages['second_age_multi']?.['max']}}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="p-1 align-items-start mt-0">
                      <div
                        class="p-2 lightcbg my-2 d-flex align-items-center rounded col-12"
                      >
                        <small class="smalltext"
                          >The travel policy covers up to two adult travellers.
                          Please enter the current age of the adult travellers.
                          Dependents such as children, grandchildren,
                          step-children and foster children aged 25 or under at
                          no extra cost. Specific criteria apply.</small
                        >
                        <ng-container
                          *ngIf="
                            !isTooltipVisibleDep;
                            else dependentToolTipOnHover
                          "
                        >
                          <img
                            src="assets/images/racq/help.png"
                            class="tooltipc"
                            [pTooltip]="dependentTooltip"
                            tooltipPosition="right"
                            tooltipStyleClass="dependent-tooltip"
                          />
                        </ng-container>
                        <ng-template #dependentToolTipOnHover>
                          <div
                            (click)="toggleTooltip()"
                            class="tooltip-container"
                          >
                            <img
                              src="assets/images/racq/help.png"
                              class="tooltipc"
                            />
                            <ng-container *ngIf="isTooltipVisibleDep">
                              <ng-container
                                *ngTemplateOutlet="dependentTooltip"
                              ></ng-container>
                            </ng-container>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <div class="g-2 form-floating">
                      <input
                        type="tel"
                        [(ngModel)]="dependentsCount"
                        class="form-control"
                        placeholder=""
                        aria-label="Dependents Count"
                        maxLength="2"
                        formControlName="dependentsCount"
                        pKeyFilter="int"
                      />
                      <label for="dependents">No. of Dependents</label>
                    </div>
                    <div class="g-2 col-12">
                      <div class="select-container">
                        <select
                          [(ngModel)]="state"
                          name=""
                          id="state"
                          class="form-select select"
                          formControlName="state"
                        >
                          <option
                            *ngFor="let state of stateList"
                            value="{{ state.id }}"
                          >
                            {{ state.code }}
                          </option>
                        </select>
                        <label
                          class="label"
                          for="state"
                          [class.floating]="state"
                          >State *</label
                        >
                      </div>
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <ng-container
                          *ngIf="
                            multiTripForm.get('state')?.invalid &&
                            (multiTripForm.get('state')?.dirty ||
                              multiTripForm.get('state')?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="multiTripForm.get('state')?.errors?.['required']"
                            >
                              Please select the State you reside in.
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="g-2 form-floating">
                      <input
                        [(ngModel)]="promoCode"
                        type="text"
                        class="form-control"
                        placeholder=""
                        (blur)="validatePromoCode($event)"
                        formControlName="promoCode"
                      />
                      <label for="floatingPromocode">Enter a Promo Code</label>
                    </div>
                    <div class="align-items-center">
                      <div class="col-12">
                        <ng-container>
                          <small class="text-danger">
                            <span>
                              {{ promoCodeMessage }}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                    <div class="g-2 col-12 col-sm-12">
                      <button
                        type="submit"
                        class="btn btn-lg qbtn btn-info w-100 text-center py-2"
                        (click)="onMultiTripSubmit()"
                      >
                        Get a Quote
                      </button>
                    </div>
                  </div>
                  <!-- Disclaimer -->
                  <div class="row g-2 align-items-start" *ngIf="sanitizedHtml">
                    <div class="col-12 col-sm-12 col-md-12 col-xl">
                      <div class="p-2 lightcbg my-2 align-items-center rounded">
                        <small><strong>Disclaimer</strong></small>
                        <p></p>
                        <p class="smalltext" [innerHTML]="sanitizedHtml"></p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #travelDestinationTooltip>
    <div
      [ngClass]="
        isTooltipVisibleTravDest ? { 'tooltip-content': true, show: true } : ''
      "
    >
      If you are going on an Australian ocean cruise which is 2 nights or more,
      you may still require a policy which includes medical cover whilst you are
      on board the ship (check with your cruise provider) as the medical
      providers are not registered with Medicare. You will therefore need to
      select Australian Cruise as your country of destination. By selecting
      Australian Cruise as a destination means you will be offered the
      international policy options and benefits. This will then allow us to give
      you the medical and evacuation benefit whilst at sea but not if you go to
      a medical provider whilst in port in Australia.
    </div>
  </ng-template>

  <ng-template #dependentTooltip>
    <div
      [ngClass]="
        isTooltipVisible || isTooltipVisibleDep
          ? { 'tooltip-content': true, show: true }
          : ''
      "
    >
      Dependents 25 or under go free as long as they travel with you the whole
      time, are financially dependent on their parents, not working full-time,
      and do not require a medical assessment.
    </div>
  </ng-template>
</section>
